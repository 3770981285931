const _jsxFileName = "/home/runner/work/heinzerling-web/heinzerling-web/app/js/pages/orders/OrdersRoute.tsx"; function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }import {
  AdminLayout,
  search,
  SelectWithAll,
  useTranslation,
  useUrlParam,
} from "@opendash/core";
import { AdminToolbar } from "@opendash/ui";
import { observer } from "mobx-react-lite";
import Parse from "parse";
import { useParseQuery } from "parse-hooks";
import React, { useEffect, useMemo, useState } from "react";
import { OrdersList } from "../../features/orders";
import {


  OrderTechnicanRelation,
  Technican,
} from "../../parse";

export const OrdersPage = observer(() => {
  const t = useTranslation();
  const [technicians, setTechnicians] = useState([]);
  const [selectedTechnician, setSelectedTechnician] = useState([]);
  const [searchString, setSearchString] = useUrlParam(
    "query",
    "",
    "string"
  );

  const [hiddenQuery] = useUrlParam("hiddenQuery", "", "string");

  const query = useMemo(
    () =>
      // new Parse.Query(Order)
      //   .exists("date")
      //   .descending("date")
      //   .include("customer")
      //   .include("facility")
      //   .limit(1000000),
      new Parse.Query(OrderTechnicanRelation)
        .exists("start")
        .descending("start")
        .include("order")
        //@ts-ignore
        .include("order.customer")
        //@ts-ignore
        .include("order.facility")
        .include("facility")
        .include("technican")
        .limit(1000000),
    []
  );

  useEffect(() => {
    new Parse.Query(Technican).ascending("name").find().then(setTechnicians);
  }, []);

  const entries = useParseQuery(query, {
    live: true,
    liveReload: true,
    reloadThrottle: 5000,
  });

  const rows = useMemo(
    () =>
      //hiddenQuery === null ? "" : hiddenQuery
      search(
        searchString === null ? "" : searchString,
        entries.result
          .filter((row) => !!row.get("order"))
          .filter((row) => {
            if (row.get("technican") === undefined) {
              return true;
            }

            return (
              selectedTechnician.length === 0 ||
              selectedTechnician.some((id) => id === row.get("technican").id)
            );
          }),
        (row) => {
          const customer = row.get("order").get("customer") ;
          const facility = row.get("order").get("facility") ;
          const technician = row.get("technican") ;

          return {
            text: [
              row.get("order").get("no") || "",
              _optionalChain([customer, 'optionalAccess', _ => _.get, 'call', _2 => _2("name"), 'optionalAccess', _3 => _3.toLowerCase, 'call', _4 => _4()]) || "",
              _optionalChain([facility, 'optionalAccess', _5 => _5.get, 'call', _6 => _6("no"), 'optionalAccess', _7 => _7.toString, 'call', _8 => _8()]) || "",
              _optionalChain([technician, 'optionalAccess', _9 => _9.get, 'call', _10 => _10("name"), 'optionalAccess', _11 => _11.toLowerCase, 'call', _12 => _12()]) || "",
            ],
            keys: {
              // monteur: technician?.get("name") || "",
              versendet: row.get("order").get("sys_mailSent") ? "ja" : "nein",
              abgeschlossen: row.get("order").get("sys_locked")
                ? "ja"
                : "nein",
            },
          };
        }
      ).sort((a, b) => {
        return (
          (_optionalChain([b, 'access', _13 => _13.get, 'call', _14 => _14("start"), 'optionalAccess', _15 => _15.valueOf, 'call', _16 => _16()]) || 0) - (_optionalChain([a, 'access', _17 => _17.get, 'call', _18 => _18("start"), 'optionalAccess', _19 => _19.valueOf, 'call', _20 => _20()]) || 0)
        );
      }),
    [
      entries.result.length,
      searchString,
      hiddenQuery,
      selectedTechnician.length,
    ]
  );

  const uniqueOrders = (rows) => {
    return Object.values(
      rows.reduce(
        (
          acc,
          otr
        ) => {
          if (!acc[otr.get("order").id]) {
            acc[otr.get("order").id] = otr;
          }
          return acc;
        },
        {} 
      )
    );
  };

  return (
    React.createElement(React.Fragment, null
      , React.createElement(AdminLayout, { contentPadding: true, __self: this, __source: {fileName: _jsxFileName, lineNumber: 135}}
        , React.createElement(AdminToolbar, {
          title: t("app:admin.order.title"),
          description: t("app:admin.order.description"),
          // actions={[
          //   <Button
          //     key="new"
          //     type="primary"
          //     icon={<Icon icon="fa:plus" />}
          //     children={t("app:admin.order.action_new")}
          //     onClick={() => {
          //       $parse.ui.createObject("Auftrag").then((objectId) => {
          //         entries.reload();
          //       });
          //     }}
          //   />,
          // ]}
          searchInitial: searchString,
          onSearch: setSearchString,
          children: 
            React.createElement(SelectWithAll, {
              value: selectedTechnician,
              options: technicians.map((technician) => {
                return {
                  label: technician.get("name"),
                  value: technician.id,
                  key: technician.id,
                };
              }),
              onChange: setSelectedTechnician,
              allLabel: "Alle",
              style: { width: "100%", marginBottom: 8 }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 155}}
            )
          , __self: this, __source: {fileName: _jsxFileName, lineNumber: 136}}
        )
        , React.createElement(OrdersList, { rows: uniqueOrders(rows), loading: entries.loading, __self: this, __source: {fileName: _jsxFileName, lineNumber: 170}} )
        /* <Detector
          offlineChildren={
            <Empty
              style={{ marginTop: 24 }}
              children="Sie sind offline. Die Aufträge können nicht angezeigt werden."
              description={<Title level={5}>OFFLINE</Title>}
            />
          }
          onlineChildren={
            <>
              
            </>
          }
        /> */
      )
    )
  );
});

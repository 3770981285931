const _jsxFileName = "/home/runner/work/heinzerling-web/heinzerling-web/app/js/pages/overview/WeekOverviewPage.tsx";import { AdminLayout, useTranslation } from "@opendash/core";
import { AdminToolbar } from "@opendash/ui";

import { Checkbox, DatePicker, List, Space, Tabs } from "antd";
import { Col, Row } from "antd/lib";
import { observer } from "mobx-react-lite";
import React, { useEffect, useMemo } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { OrdersList } from "../../features/orders";
import { WeekOverviewState } from "../../features/overview";
import { formatDate } from "../../helper/formatter";

const DATE_FORMAT = "YYYY-MM-DD";

export const WeekOverviewPage = observer(() => {
  const t = useTranslation();
  const navigate = useNavigate();

  const state = useMemo(() => new WeekOverviewState(), []);

  const { RangePicker } = DatePicker;

  const { startParam, endParam } = useParams();
  let [searchParams, setSearchParams] = useSearchParams();

  const tabitems = [
    {
      key: "orders",
      label: t("app:overview.auftrag_tab"),
      children: (
        React.createElement(OrdersList, {
          rows: state.orders,
          loading: state.loading,
          hideElevatorLink: true, __self: this, __source: {fileName: _jsxFileName, lineNumber: 31}}
        )
      ),
    },
    {
      key: "item",
      label: t("app:overview.artikel_tab"),
      children: (
        React.createElement(List, {
          itemLayout: "vertical",
          loading: state.loading,
          dataSource: state.items,
          rowKey: (row) => row.key,
          locale: {
            emptyText: t("app:articles.list_empty"),
          },
          renderItem: (row) => {
            return (
              React.createElement(List.Item, {
                extra: 
                  React.createElement('span', { style: { fontWeight: "bold", marginLeft: 10 }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 54}}
                    , row.quantity
                  )
                , __self: this, __source: {fileName: _jsxFileName, lineNumber: 52}}

                , React.createElement(List.Item.Meta, {
                  title: row.title,
                  description: row.description, __self: this, __source: {fileName: _jsxFileName, lineNumber: 59}}
                )

                , React.createElement('div', {
                  dangerouslySetInnerHTML: {
                    __html: row.body,
                  }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 64}}
                )
              )
            );
          }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 42}}
        )
      ),
    },
  ];

  useEffect(() => {
    state.setParams(
      startParam,
      endParam,
      searchParams.get("showFinished") === "true",
      searchParams.get("showOpen") === "true",
      searchParams.get("showOwnOrdersOnly") === "true"
    );
  }, [
    startParam,
    endParam,
    searchParams.get("showFinished"),
    searchParams.get("showOpen"),
    searchParams.get("showOwnOrdersOnly"),
  ]);

  useEffect(() => {
    const start = state.start.format(DATE_FORMAT);
    const end = state.end.format(DATE_FORMAT);
    const qs = "".concat(
      ...[
        `showFinished=${state.showFinished}`,
        "&",
        `showOpen=${state.showOpen}&showOwnOrdersOnly=${state.showOwnOrdersOnly}`,
      ]
    );

    navigate(`/overview/${start}/${end}?${qs}`, {
      replace: true,
    });
  }, [
    state.start.valueOf(),
    state.end.valueOf(),
    state.showFinished,
    state.showOpen,
    state.showOwnOrdersOnly,
  ]);

  // React.useEffect(() => {
  //   console.log(orders.result, item.result);
  // }, [orders.result, item.result]);

  return (
    React.createElement(AdminLayout, { contentPadding: true, __self: this, __source: {fileName: _jsxFileName, lineNumber: 120}}
      , React.createElement(AdminToolbar, {
        title: t("app:overview.title"),
        description: t("app:overview.time_range", {
          start: formatDate(state.start.toDate()),
          end: formatDate(state.end.toDate()),
        }),
        // search={searchString}
        // onSearch={setSearchString}
        children: 
          React.createElement(Space, { direction: "vertical", size: "large", style: { display: "flex" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 130}}
            , React.createElement('div', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 131}}
              , React.createElement(RangePicker, {
                style: { width: "100%" },
                value: [state.start, state.end],
                onChange: (value) => {
                  if (value) {
                    const [start, end] = value;

                    if (start && end) {
                      state.setTimerange(start, end);
                    }
                  }
                }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 132}}
              )
            )

            , React.createElement(Row, { gutter: [8, 8], __self: this, __source: {fileName: _jsxFileName, lineNumber: 147}}
              , React.createElement(Col, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 148}}
                , React.createElement(Checkbox, {
                  children: t("app:overview.show_open"),
                  checked: state.showOpen,
                  onChange: (e) => {
                    state.setShowOpen(e.target.checked);
                  }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 149}}
                )
              )
              , React.createElement(Col, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 157}}
                , React.createElement(Checkbox, {
                  children: t("app:overview.show_finished"),
                  checked: state.showFinished,
                  onChange: (e) => {
                    state.setShowFinished(e.target.checked);
                  }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 158}}
                )
              )
              , React.createElement(Col, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 166}}
                , React.createElement(Checkbox, {
                  children: t("app:overview.show_OwnOrdersOnly"),
                  checked: state.showOwnOrdersOnly,
                  onChange: (e) => {
                    state.setShowOwnOrdersOnly(e.target.checked);
                  }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 167}}
                )
              )
            )
          )
        , __self: this, __source: {fileName: _jsxFileName, lineNumber: 121}}
      )

      , React.createElement(Tabs, {
        activeKey: state.tab,
        onChange: (tab) => state.setTab(tab),
        items: tabitems, __self: this, __source: {fileName: _jsxFileName, lineNumber: 180}}
      )
    )
  );
});

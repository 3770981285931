 function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }import { makeAutoObservable, runInAction } from "@opendash/state";
import { formatAddress } from "../../../helper/formatter";
import { Facility, OrderType, Technican } from "../../../parse";
import { $heinzerling } from "../../../service";

export class OrderCreateState {
   __init() {this.loading = true}
   __init2() {this.facilities = []}
   __init3() {this.technicians = []}
   __init4() {this.userTechnician = null}
   __init5() {this.selectedFacility = null}
   __init6() {this.orderTypes = []}

  constructor() {;OrderCreateState.prototype.__init.call(this);OrderCreateState.prototype.__init2.call(this);OrderCreateState.prototype.__init3.call(this);OrderCreateState.prototype.__init4.call(this);OrderCreateState.prototype.__init5.call(this);OrderCreateState.prototype.__init6.call(this);
    makeAutoObservable(this);

    this.fetch();
  }

  async fetch() {
    const user = await $heinzerling.sync.getUser();

    const userTechnician =
      (await _optionalChain([user, 'optionalAccess', _ => _.get, 'call', _2 => _2("technican")])) || null;

    // if (userTechnician) {
    //   await userTechnician.fetch();
    // }

    const facilities = await $heinzerling.sync
      .createQuery(Facility)
      .include("customer")
      .ascending("no")
      .limit(1000000)
      .find();

    const technicians = await $heinzerling.sync
      .createQuery(Technican)
      .ascending("name")
      .limit(1000000)
      .find();

    const orderTypes = await $heinzerling.sync
      .createQuery(OrderType)
      .ascending("abbreviation")
      .find();

    runInAction(() => {
      this.loading = false;

      this.facilities = facilities;
      this.technicians = technicians;
      this.userTechnician = userTechnician;
      this.orderTypes = orderTypes;
    });
  }

   get facilitiesForSelect() {
    return this.facilities
      .map((facility) => {
        if (typeof facility.get("customer") !== "undefined") {
          return {
            label: `[${facility.get("no")}] ${facility
              .get("customer")
              .get("name")} - ${formatAddress(facility)}`,
            value: facility.id,
          };
        }
        return undefined;
      })
      .filter(Boolean) ;
  }

   get Technicians() {
    return this.technicians;
  }

   get TechniciansForSelect() {
    return this.technicians.map((technician) => {
      return {
        label: technician.get("name"),
        value: technician.id,
      };
    });
  }

   get SelectedFacility() {
    return this.selectedFacility;
  }

   set SelectedFacility(value) {
    this.selectedFacility = value;
  }
}
